<template>
  <transition name="notification">
    <div v-if="show" class="notification pointer-events-none fixed inset-x-5 top-5 z-50" @click="close">
      <div class="pointer-events-auto mx-auto max-w-3xl overflow-hidden rounded-3xl">
        <div class="flex items-center gap-2 bg-white/80 p-4 backdrop-blur-xl">
          <div class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-gray-800">
            <IconDone :size="38" v-if="type == 'success'" />
            <IconInfo :size="33" v-if="type == 'info'" />
            <IconWarning :size="33" v-if="type == 'warning'" />
            <IconError :size="35" v-if="type == 'error'" />
          </div>
          <div class="flex flex-col text-black">
            <p>
              <strong class="line-clamp-1">
                <slot name="title" />
              </strong>
            </p>
            <p class="text-caption line-clamp-2">
              <slot name="description" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import IconDone from './icons/IconDone.vue'
import IconInfo from './icons/IconInfo.vue'
import IconWarning from './icons/IconWarning.vue'
import IconError from './icons/IconError.vue'

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'info',
    validator: value => ['info', 'success', 'warning', 'error'].includes(value)
  },
  action: {
    type: String,
    default: null
  }
})

const emit = defineEmits(['close'])

const { action } = props

function close() {
  if (action) {
    window.postMessage({
      type: 'notification',
      action
    })
  }
  emit('close')
}
</script>

<style scoped>
.notification {
  top: calc(var(--inset-top) + 1.25rem);
}
</style>
