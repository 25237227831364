<template>
  <router-link v-if="backLink" :to="to" class="nav-button">
    <IconBackIOS class="h-4 w-4" />
    {{ label }}
  </router-link>
  <button v-else-if="type === 'back'" class="nav-button" @click="goBack">
    <IconBackIOS class="h-4 w-4" />
    {{ label || $t('navigationBar.back') }}
  </button>
  <button v-else class="nav-button" @click="$emit('click')">
    <IconClose v-if="['close', 'cancel'].includes(type)" class="h-5 w-5 shrink-0" />
    <IconDone v-else-if="type == 'done'" class="h-5 w-5 shrink-0" />
    <IconPlus v-else-if="type == 'add'" class="h-5 w-5 shrink-0" />
    <IconSearch v-else-if="type == 'search'" class="h-5 w-5 shrink-0" />
    {{ label }}
  </button>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { IconBackIOS, IconClose, IconDone, IconPlus, IconSearch } from './icons'

const props = defineProps({
  type: {
    type: String,
    default: 'plain'
  },
  backLink: {
    type: Object,
    default: null
  },
  backLabel: {
    type: String,
    default: null
  }
})

defineEmits(['click'])

const { backLink, backLabel, type } = props
const { t } = useI18n()
const router = useRouter()

const to = computed(() => {
  return backLink.to
})
const label = computed(() => {
  return backLabel || backLink?.label || t(`navigationBar.${type || 'cancel'}`)
})

function goBack() {
  const url = document.location.href
  router.go(-1)
  setTimeout(() => {
    if (document.location.href === url) {
      router.replace({ name: 'menu' })
    }
  }, 500)
}
</script>

<style>
.nav-button {
  @apply flex w-full items-center drop-shadow-md;
}
</style>
