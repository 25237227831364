import { Howl } from 'howler'

import {
  answerGiven,
  answerCorrect,
  answerWrong,
  foundGame,
  backgroundMusic,
  gameMusic
} from '../assets/sounds'

function buildOptions(src) {
  return {
    src: [src],
    preload: true
  }
}

const sounds = {
  answerGiven: new Howl(buildOptions(answerGiven)),
  answerCorrect: new Howl(buildOptions(answerCorrect)),
  answerWrong: new Howl(buildOptions(answerWrong))
}

const music = {
  foundGame,
  backgroundMusic,
  gameMusic
}

class SoundManager {
  constructor() {
    this.onVisibiltyChange = this.onVisibiltyChange.bind(this) // Bind this to function

    this.playingMusic = []
    this.playingText = null
    this.addObservers()
  }

  playSound(key) {
    if (localStorage.getItem('settings.hasSound') !== '0') {
      sounds[key].play()
    }
  }

  async playMusic(key = 'backgroundMusic', { loop, volume } = {}) {
    if (localStorage.getItem('settings.hasMusic') !== '1' || this.playingMusic.find(m => m.key === key)) {
      return new Promise(resolve => resolve())
    }

    return new Promise(resolve => {
      this.stopMusic()

      const sound = new Howl({
        ...buildOptions(music[key]),
        ...{
          autoplay: true,
          loop: loop != false,
          volume: volume || 0.2,
          onend: function () {
            resolve()
          }
        }
      })
      this.playingMusic.push({ key, sound })
    })
  }

  stopMusic() {
    this.playingMusic.forEach(m => m.sound.stop())
    this.playingMusic = []
  }

  playText(src) {
    this.playingText?.sound?.stop()
    this.playingText = null

    if (!src || localStorage.getItem('settings.hasText2Speech') === '0') {
      return
    }
    window.Howl = Howl
    window.buildOptions = buildOptions
    this.playingText = new Howl(buildOptions(src))
    this.playingText.play()
  }

  addObservers() {
    document.addEventListener('visibilitychange', this.onVisibiltyChange)
  }

  removeObservers() {
    document.removeEventListener('visibilitychange', this.onVisibiltyChange)
  }

  onVisibiltyChange() {
    Howler.mute(document.visibilityState !== 'visible')
  }
}

export const soundManager = new SoundManager()
