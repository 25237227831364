import axios from 'axios'
import { LOCAL_STORAGE_KEYS } from '../constants'

const instance = axios.create({
  baseURL: '/'
})

instance.interceptors.request.use(
  async request => {
    if (!request.data) {
      request.data = {}
    }

    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)
    if (accessToken) {
      request.headers['Authorization'] = accessToken
    }
    if (!request.headers['Content-Type']) {
      request.headers['Content-Type'] = 'application/json'
    }
    request.headers['Accept'] = 'application/json'

    if (['put', 'post', 'delete'].includes(request.method)) {
      request.headers['X-CSRF-Token'] = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
    }
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

export default instance
