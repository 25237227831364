import http from './http'
import APIService from './apiService'
import { API_ENDPOINTS, WEB_ENDPOINTS } from '../constants'

export default {
  async load() {
    const url = APIService.buildUrl(API_ENDPOINTS.user)
    const { data } = await http.get(url)
    return data
  },

  async signup(form) {
    const url = WEB_ENDPOINTS.users
    const {
      data,
      headers: { authorization: token }
    } = await http.post(url, form)
    return { data, token }
  },

  async signin(form) {
    const url = WEB_ENDPOINTS.signin
    const {
      data,
      headers: { authorization: token }
    } = await http.post(url, { user: form })

    return { data, token }
  },

  async signout() {
    const url = WEB_ENDPOINTS.signout
    await http.delete(url)
  },

  async update(form) {
    const formData = new FormData()
    Object.keys(form).forEach(key => {
      if (form[key]) {
        formData.append(key, form[key])
      }
    })
    const url = APIService.buildUrl(API_ENDPOINTS.user)
    const headers = { 'Content-Type': 'multipart/form-data' }
    const { data } = await http.patch(url, formData, { headers })

    return data
  },

  async search(query) {
    const url = APIService.buildUrl(API_ENDPOINTS.users)
    const { data } = await http.get(url, { params: { query } })
    return data
  },

  async authToken() {
    try {
      const url = APIService.buildUrl(API_ENDPOINTS.userAuthToken)
      const { data } = await http.post(url)
      return data.authToken
    } catch (error) {
      console.error('Error while fetching authToken: ', error)
      return null
    }
  },

  async requestNewPassword(email) {
    const url = WEB_ENDPOINTS.password
    const headers = { 'Content-Type': 'multipart/form-data' }
    const formData = new FormData()
    formData.append('user[email]', email)
    const result = await http.post(url, formData, { headers })
    return result.status === 201
  },

  async updatePassword(password, passwordConfirmation, token) {
    const url = WEB_ENDPOINTS.password
    const formData = new FormData()
    formData.append('user[password]', password)
    formData.append('user[password_confirmation]', passwordConfirmation)
    formData.append('user[reset_password_token]', token)
    const { data } = await http.patch(url, formData)
    return data
  },

  async deleteAccount(password) {
    const url = WEB_ENDPOINTS.removeUser
    const formData = new FormData()
    formData.append('user[password]', password)
    const result = await http.post(url, formData)
    return result.status === 200
  }
}
