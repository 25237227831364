import { API_PATH } from '../constants'
import { replacePlaceholders } from '../helpers/functions'

export default {
  buildUrl(endpoint, parameters = {}) {
    const replacements = {
      ...parameters
    }
    return replacePlaceholders(API_PATH + endpoint, replacements)
  }
}
