import { computed } from '@vue/reactivity'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { createConsumer } from '@rails/actioncable'
import { storeToRefs } from 'pinia'
import { useUserStore } from './user'
import { soundManager } from '../services/SoundManager'
import gameService from '../services/gameService'
import userService from '../services/userService'
import { useI18n } from 'vue-i18n'

const consumer = createConsumer()
window.consumer = consumer // TODO

export const useGameStore = defineStore('game', () => {
  const category = ref(null)
  const gameId = ref(null)
  const state = ref('undefined')
  const roundState = ref('unstarted')
  const members = ref([])
  const points = ref({})
  const givenAnswers = ref({})
  const lobbyMemberId = ref(null)

  const round = ref(1)
  const questions = ref([])

  const { locale } = useI18n()

  const currentQuestion = computed(() => {
    return questions.value[round.value - 1]
  })

  const myGivenAnswer = computed(() => {
    const answerId = givenAnswers.value[gameMember.value.id]?.id
    if (answerId) {
      return currentQuestion.value?.answers.find(a => a.id === answerId)
    }
    return null
  })

  const gameMember = computed(() => {
    return members.value.find(member => member.isMe)
  })

  async function endRound() {
    roundState.value = 'ending'
    await gameService.endRound({ gameId: gameId.value, questionId: currentQuestion.value.id })
  }

  function finishQuestion() {
    givenAnswers.value = {}

    if (round.value + 1 > questions.value.length) {
      endGame()
    } else {
      round.value += 1
      roundState.value = 'unstarted'
    }
  }

  async function setAnswer(answerId, percent) {
    const questionId = currentQuestion.value.id
    await gameService.answer({ gameId: gameId.value, questionId, answerId, percent })
  }

  async function setAIAnswer(memberId, isCorrect, percent) {
    const questionId = currentQuestion.value.id
    await gameService.AIAnswer({
      gameId: gameId.value,
      memberId,
      questionId,
      isCorrect,
      percent
    })
  }

  function startGame(game) {
    members.value = game.gameMembers.sort(left => (left.isMe ? -1 : 1))
    gameId.value = game.id
    points.value = {}
    questions.value = game.questions
    const member = members.value.find(m => m.isMe)

    consumer.subscriptions.create(
      { channel: 'GameChannel', gameId: game.id, memberId: member?.id, isHost: Boolean(member?.isHost) },
      {
        connected() {
          console.log('connected! to game')
        },
        disconnected() {
          // TODO: handle!
          console.log('disconnected? to game')
        },
        rejected() {
          console.log('rejected? to game')
        },
        received(data) {
          // console.log('data!', data)
          if (data?.type === 'answer') {
            const newAnswer = {}
            newAnswer[`${data.gameMemberId}`] = {
              id: data.answerId,
              percent: data.percent || 0
            }

            givenAnswers.value = {
              ...givenAnswers.value,
              ...newAnswer
            }
          } else if (data?.type === 'end_round') {
            const newPoints = {}
            const correctAnswerIds = data.answers.filter(a => a.correct).map(a => a.id)
            data.gameMembers.forEach(member => {
              newPoints[member.id] = member.xp
            })
            points.value = {
              ...points.value,
              ...newPoints
            }
            questions.value = questions.value.map(question => {
              if (question.id === currentQuestion.value.id) {
                question.answers = question.answers.map(answer => {
                  return {
                    ...answer,
                    ...{
                      isCorrect: data.answers.find(a => a.id === answer.id)?.correct == true
                    }
                  }
                })
              }
              return question
            })
            roundState.value = 'ended'

            const member = members.value.find(m => m.isMe)
            if (
              givenAnswers.value[member.id]?.id &&
              correctAnswerIds.includes(givenAnswers.value[member.id].id)
            ) {
              soundManager.playSound('answerCorrect')
            } else if (givenAnswers.value[member.id]?.id) {
              soundManager.playSound('answerWrong')
            }

            setTimeout(finishQuestion, 2000)
          } else if (data?.type === 'disconnect') {
            const index = members.value.findIndex(member => member.id === data.gameMemberId)
            members.value[index].state = 'disconnected'

            if (data.newHostId) {
              const hostIndex = members.value.findIndex(member => member.id === data.newHostId)
              members.value[hostIndex].isHost = true
            }
          } else if (data?.type === 'rematch') {
            const index = members.value.findIndex(member => member.id === data.gameMemberId)
            if (index >= 0) {
              members.value[index].state = data.rematch ? 'rematch' : 'disconnected'
            }

            if (
              gameMember.value?.isHost &&
              members.value.filter(
                member =>
                  member.aiDifficulty || member.isGuest || ['rematch', 'disconnected'].includes(member.state)
              ).length == members.value.length
            ) {
              consumer.subscriptions?.subscriptions.forEach(subscription => {
                subscription.send({
                  type: 'rematch',
                  memberIds: members.value.filter(m => m.state === 'rematch').map(m => m.id)
                })
              })
            }
          } else if (data?.type === 'restart') {
            window.postMessage({ type: 'restart', lobbyMembers: data.lobbyMembers })
          }
        }
      }
    )

    setTimeout(async () => {
      state.value = 'created'
      await soundManager.playMusic('foundGame', { loop: false })
      await soundManager.playMusic('gameMusic')
    }, 100)

    setTimeout(() => {
      state.value = 'playing'
    }, 3500)
  }

  async function findGame(searchLobbyMemberId) {
    lobbyMemberId.value = searchLobbyMemberId
    category.value = ''
    state.value = 'inited'
    round.value = 1
    roundState.value = 'unstarted'
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)
    members.value = [user.value]

    if (!user.value?.id) {
      throw 'Missing user id'
    }
    let authToken
    if (user.value?.isGuest === false) {
      authToken = await userService.authToken()
    }

    function onVisibiltyChange() {
      const isActive = document.visibilityState === 'visible'
      console.log('isActive: ', isActive)
      consumer.subscriptions?.subscriptions.forEach(subscription => {
        subscription.send({ is_active: isActive })
      })
    }

    function addObservers() {
      document.addEventListener('visibilitychange', onVisibiltyChange)
    }

    function removeObservers() {
      document.removeEventListener('visibilitychange', onVisibiltyChange)
    }

    console.log('want connect: ', user.value, locale.value)
    const subscription = consumer.subscriptions.create(
      {
        channel: 'GameRequestChannel',
        userId: user.value.id,
        category: category.value,
        guestName: user.value.name,
        avatarUrl: user.value.avatarUrl,
        lobbyMemberId: lobbyMemberId.value,
        locale: locale.value,
        authToken
      },
      {
        connected() {
          console.log('connected!')
          addObservers()
        },
        disconnected() {
          console.log('disconnected?')
          removeObservers()
        },
        rejected() {
          console.log('rejected?')
          removeObservers()
        },
        received(data) {
          try {
            if (data?.hallo) {
              document.getElementById('dummy').textContent = data.hallo
            }
          } catch {
            //
          }
          console.log('data!', data)
          if (!data?.game) {
            return
          }

          startGame(data.game)
          subscription.unsubscribe()
        }
      }
    )
  }

  function endSubscriptions() {
    const subscriptions = consumer.subscriptions.subscriptions
    subscriptions.forEach(sub => sub.unsubscribe())
  }

  function endGame() {
    state.value = 'ended'
  }

  function cancelGame() {
    endSubscriptions()
    state.value = 'undefined'
    round.value = 1
    roundState.value = 'unstarted'
    lobbyMemberId.value = null
  }

  function wantRestartGame(rematch) {
    consumer.subscriptions?.subscriptions.forEach(subscription => {
      subscription.send({ rematch: rematch === true })
    })
  }

  function getConsumer() {
    return consumer
  }

  return {
    category,
    gameId,
    state,
    roundState,
    members,
    points,
    givenAnswers,
    round,
    questions,
    currentQuestion,
    myGivenAnswer,
    gameMember,
    lobbyMemberId,
    endRound,
    finishQuestion,
    setAnswer,
    setAIAnswer,
    findGame,
    wantRestartGame,
    cancelGame,
    getConsumer
  }
})
