import { reactive } from 'vue'

const notification = reactive({})
let timeout = null

export default function useNotification() {
  const notify = type => {
    return (title, description, action) => {
      notification.show = true
      notification.type = type
      notification.title = title
      notification.description = description
      notification.action = action
      console.log('action: ', action)

      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(() => {
        notification.show = false
      }, 5000)
    }
  }
  const showInfo = notify('info')
  const showSuccess = notify('success')
  const showWarning = notify('warning')
  const showError = notify('error')

  return { notification, showInfo, showSuccess, showWarning, showError }
}
