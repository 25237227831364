import http from './http'
import APIService from './apiService'
import { API_ENDPOINTS } from '../constants'

export default {
  async answer({ gameId, questionId, answerId, percent }) {
    const url = APIService.buildUrl(API_ENDPOINTS.answers, { gameId, questionId })
    const { data } = await http.post(url, { answerId, percent })
    return data
  },

  async AIAnswer({ gameId, memberId, questionId, isCorrect, percent }) {
    const url = APIService.buildUrl(API_ENDPOINTS.answers, { gameId, questionId })
    const { data } = await http.post(url, { memberId, isCorrect, percent, questionId })
    return data
  },

  async endRound({ gameId, questionId }) {
    const url = APIService.buildUrl(API_ENDPOINTS.question, { gameId, questionId })
    const { data } = await http.delete(url)
    return data
  }
}
