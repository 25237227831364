import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

export const useUIStore = defineStore('ui', () => {
  const showFriendSearchModal = ref(false)
  const showNotficationsModal = ref(false)
  const navigationBarCount = ref(0)

  watch(navigationBarCount, newValue => {
    window.webkit?.messageHandlers?.guessWhat?.postMessage({ type: 'navigationBar', value: newValue > 0 })
  })

  return {
    showFriendSearchModal,
    showNotficationsModal,
    navigationBarCount
  }
})
