export const MENU_BUTTON_ROUTE_NAMES = ['game', 'friends', 'settings']

export const API_PATH = '/api/v1/'
export const API_ENDPOINTS = {
  user: 'users/current',
  users: 'users',
  userAuthToken: 'users/current/auth_token',
  friendships: 'users/current/friendships',
  userFriendship: 'users/current/friendships/{userId}',
  question: 'games/{gameId}/questions/{questionId}',
  answers: 'games/{gameId}/questions/{questionId}/answers',
  categories: 'categories',
  lobbies: 'lobbies',
  lobby: 'lobbies/{id}'
}

export const WEB_ENDPOINTS = {
  users: 'users',
  signout: 'users/sign_out',
  signin: 'users/sign_in',
  password: 'users/password',
  removeUser: 'users/remove'
}

export const PAGE_TITLE = 'Guess What'

export const LOCAL_STORAGE_KEYS = {
  GUEST_NAME: 'guest-name',
  AUTH_TOKEN: 'auth-token'
}

export const ONLINE_STATES = {
  ONLINE: 'online',
  OFFLINE: 'offline',
  INACTIVE: 'inactive'
}

export const MAX_NAME_LENGTH = 10

export const IS_APP = Boolean(window.webkit?.messageHandlers?.guessWhat)

export const DUMMY_AVATAR_COUNT = 23

export const SUPPORT_EMAIL = 'contact@guesswhatapp.de'
