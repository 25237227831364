import { createRouter, createWebHistory } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useUserStore } from '../stores/user'
import { PAGE_TITLE } from '../constants'

const Menu = () => import('@/app/pages/Menu.vue')
const Game = () => import('@/app/pages/Game.vue')
const Friends = () => import('@/app/pages/Friends.vue')
const Settings = () => import('@/app/pages/Settings.vue')

const PreGame = () => import('@/app/pages/PreGame.vue')
const SignIn = () => import('@/app/pages/SignIn.vue')
const SignUp = () => import('@/app/pages/SignUp.vue')
const ResetPassword = () => import('@/app/pages/ResetPassword.vue')
const EditPassword = () => import('@/app/pages/EditPassword.vue')
const Account = () => import('@/app/pages/Account.vue')
const DeleteAccount = () => import('@/app/pages/DeleteAccount.vue')
const Welcome = () => import('@/app/pages/Welcome.vue')
const Lobby = () => import('@/app/pages/Lobby.vue')

const Imprint = () => import('@/app/pages/Imprint.vue')
const Terms = () => import('@/app/pages/Terms.vue')
const Privacy = () => import('@/app/pages/Privacy.vue')

const NotFound = () => import('@/app/pages/NotFound.vue')

const DebugQuiz = () => import('@/app/debug/DebugQuiz.vue')
const DebugResult = () => import('@/app/debug/DebugResult.vue')

export const routes = [
  {
    name: 'menu',
    path: '/',
    component: Menu
  },
  {
    name: 'game',
    path: '/game/:lobbyMemberId?',
    component: Game,
    props: true,
    meta: {
      title: 'Spiel' // TODO: i18n
    }
  },
  {
    name: 'friends',
    path: '/firends',
    component: Friends,
    meta: {
      title: 'Freunde', // TODO: i18n
      onlyAccessableWithUser: true
    }
  },
  {
    name: 'settings',
    path: '/settings',
    component: Settings,
    meta: {
      title: 'Einstellungen' // TODO: i18n
    }
  },
  {
    name: 'pre-game',
    path: '/pre-game',
    component: PreGame,
    meta: {
      title: 'Spiel starten'
    }
  },
  {
    name: 'sign-in',
    path: '/sign-in',
    component: SignIn,
    meta: {
      onlyAccessableWithoutUser: true
    }
  },
  {
    name: 'sign-up',
    path: '/sign-up',
    component: SignUp,
    meta: {
      onlyAccessableWithoutUser: true
    }
  },
  {
    name: 'reset-password',
    path: '/reset-password',
    component: ResetPassword
  },
  {
    name: 'edit-password',
    path: '/password/edit',
    component: EditPassword
  },
  {
    name: 'account',
    path: '/account',
    component: Account,
    meta: {
      onlyAccessableWithUser: true
    }
  },
  {
    name: 'delete-account',
    path: '/account/delete',
    component: DeleteAccount,
    meta: {
      onlyAccessableWithUser: true
    }
  },
  {
    name: 'welcome',
    path: '/welcome',
    component: Welcome,
    meta: {
      onlyAccessableWithoutUser: true
    }
  },
  {
    name: 'lobby',
    path: '/lobbies/:id',
    component: Lobby,
    props: true,
    meta: {
      onlyAccessableWithUser: true
    }
  },
  {
    name: 'imprint',
    path: '/imprint',
    component: Imprint,
    meta: {
      isPublic: true
    }
  },
  {
    name: 'terms',
    path: '/terms',
    component: Terms,
    meta: {
      isPublic: true
    }
  },
  {
    name: 'privacy',
    path: '/privacy',
    component: Privacy,
    meta: {
      isPublic: true
    }
  },
  {
    name: 'not-found',
    path: '/404',
    component: NotFound,
    meta: {
      isPublic: true
    }
  },
  {
    name: 'redirect',
    path: '/:catchAll(.*)*',
    component: NotFound,
    meta: {
      isPublic: true
    }
  },
  {
    name: 'debug',
    path: '/debug/quiz',
    component: DebugQuiz
  },
  {
    name: 'debug',
    path: '/debug/result',
    component: DebugResult
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior(_, __, savedPosition) {
    return savedPosition ? savedPosition : { top: 0 }
  }
})

router.beforeEach(async to => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title)
  // If a route with a title was found, set the document (page) title to that value.
  let titleParts = [PAGE_TITLE]
  const customTitle = nearestWithTitle?.meta?.title
  if (customTitle) {
    titleParts.push(customTitle)
  }
  document.title = titleParts.join(' - ')

  if (to.meta?.isPublic) {
    return
  }

  const userStore = useUserStore()
  const { user, guestName } = storeToRefs(userStore)
  if (!user.value) {
    const result = await userStore.loadUserData()
    if (!result) {
      return {
        name: 'not-found'
      }
    }
  }

  if (user.value?.isGuest == false && to.meta?.onlyAccessableWithoutUser) {
    return {
      name: 'menu'
    }
  } else if ((!user.value || user.value.isGuest) && to.meta?.onlyAccessableWithUser) {
    return {
      name: 'welcome'
    }
  }

  if (to.name === 'game' && user.value.isGuest && !guestName.value) {
    return {
      name: 'pre-game'
    }
  }
})

router.afterEach((to, from) => {
  if (history.state?.forward === from.path || to.name === 'menu') {
    to.meta.transition = 'slide-right'
    return
  } else if (history.state?.back === from.path) {
    to.meta.transition = 'slide-left'
    return
  }

  if (to.path === from.path || !from.name) {
    return
  }
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  to.meta.transition = to.path === '/' || toDepth < fromDepth ? 'slide-right' : 'slide-left'
})

export default router
