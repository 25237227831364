import UserService from '../services/userService'
import { createConsumer } from '@rails/actioncable'

const consumer = createConsumer()
window.userConsumer = consumer // TODO

export default class UserSubscription {
  constructor(user) {
    this.onVisibiltyChange = this.onVisibiltyChange.bind(this) // Bind this to function

    this.user = user
    this.subscription = null
    this.connect()
  }

  async connect() {
    if (!this.user?.id?.length) {
      throw 'Missing user id'
    }

    let authToken
    if (this.user.isGuest === false) {
      authToken = await UserService.authToken()
    }

    console.log('want connect: ', this.user)
    const that = this
    const subscription = consumer.subscriptions.create(
      {
        channel: 'UserChannel',
        userId: this.user.id,
        authToken
      },
      {
        connected(props) {
          that.subscription = this
          console.log('user connected!', props)
          that.addObservers()
        },
        disconnected() {
          console.log('user disconnected?')
          that.removeObservers()
        },
        rejected() {
          console.log('user rejected?')
        },
        received(data) {
          // console.log('data!', data)
          window.postMessage(data)
        }
      }
    )
    window.userSubscription = subscription
  }

  disconnect() {
    this.subscription?.unsubscribe()
  }

  addObservers() {
    document.addEventListener('visibilitychange', this.onVisibiltyChange)
  }

  removeObservers() {
    document.removeEventListener('visibilitychange', this.onVisibiltyChange)
  }

  onVisibiltyChange() {
    const isActive = document.visibilityState === 'visible'
    consumer.subscriptions?.subscriptions.forEach(subscription => {
      subscription.send({ is_active: isActive })
    })
  }
}
