import { createI18n } from 'vue-i18n'
import de from '../i18n/de.json'
import en from '../i18n/en.json'
import fr from '../i18n/fr.json'

const locale = document.querySelector('html').getAttribute('lang')
console.log(
  "document.querySelector('html').getAttribute('lang')",
  document.querySelector('html').getAttribute('lang')
)
const messages = {
  ...de,
  ...en,
  ...fr
}

const i18n = createI18n({
  locale,
  messages,
  legacy: false
})

export default i18n
