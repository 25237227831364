<template>
  <div :class="absoluteClass" ref="navigationBar" class="w-full bg-yellow-500">
    <header class="container flex justify-between py-3 text-gray-800">
      <div class="w-20 md:w-28">
        <slot name="left-button">
          <NavigationBarButton :back-link="backLink" :type="hasBackButton ? 'back' : null" />
        </slot>
      </div>

      <div class="text-center font-bold drop-shadow-md">
        {{ title }}
      </div>

      <div class="navigation-bar-right w-20 text-right md:w-28">
        <slot name="right-button" />
      </div>
    </header>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useUIStore } from '../stores/ui'
import NavigationBarButton from './NavigationBarButton.vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  title: {
    type: String
  },
  isAbsolute: {
    type: Boolean,
    default: true
  },
  hasBackButton: {
    type: Boolean,
    default: false
  },
  isFloatingBar: {
    type: Boolean,
    default: false
  }
})

const { title, isAbsolute, hasBackButton, isFloatingBar } = props
const { t } = useI18n()
const uiStore = useUIStore()
const { navigationBarCount } = storeToRefs(uiStore)
const navigationBar = ref(null)

const backLink = computed(() => {
  if (hasBackButton) {
    return null
  }
  return {
    to: { name: 'menu' },
    label: t('navigation.menu')
  }
})

const absoluteClass = computed(() => {
  if (isAbsolute) {
    return 'navigation-bar fixed absolute-while-animate top-0 z-10'
  } else if (isFloatingBar) {
    return null
  }
  return 'navigation-bar'
})

onMounted(() => {
  if (!isFloatingBar) {
    navigationBarCount.value += 1
  }

  // addEventListener('scroll', event => {
  //   if (document.activeElement.tagName === 'INPUT') {
  //     navigationBar.value.style.position = 'absolute'
  //     navigationBar.value.style.top = `${window.scrollY - 50}px`
  //   }
  // })
})

onUnmounted(() => {
  if (!isFloatingBar) {
    navigationBarCount.value -= 1
  }
})
</script>

<style scoped>
.navigation-bar {
  @apply pt-[var(--inset-top)] md:pt-0;
}

.navigation-bar-right :deep(.nav-button) {
  @apply flex-row-reverse;
}
</style>
