import { createApp } from 'vue'
import { createPinia } from 'pinia'

import router from '@/app/router'
import App from '@/app/App.vue'
import i18n from '@/app/services/i18n'
import A11yDialog from 'vue-a11y-dialog'
// import dayjs from 'dayjs'

import '@/app/assets/index.css'

// dayjs.extend(localizedFormat)

const pinia = createPinia()
const app = createApp(App)

app.use(i18n)
app.use(pinia)
app.use(router)
app.use(A11yDialog)
app.mount('#app')
