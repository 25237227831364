<script setup>
import { computed } from 'vue'
import IconLoading from './icons/IconLoading.vue'

const props = defineProps({
  variant: {
    type: String,
    default: 'default',
    validator: value => ['default', 'danger', 'silent', 'text', 'secondary'].includes(value)
  },
  type: {
    type: String,
    default: 'button'
  },
  size: {
    type: String,
    default: 'm',
    validator: value => ['xs', 's', 'm'].includes(value)
  },
  disabled: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  loadingLabel: {
    type: String,
    default: null
  },
  fullWidth: {
    type: Boolean,
    default: true
  }
})

const { variant, size, disabled, fullWidth } = props

const variantClass = computed(() => {
  const widthClass = fullWidth ? 'w-full' : 'w-auto'
  switch (variant) {
    case 'default':
      return `${widthClass} border-gray-800 bg-yellow-500 text-gray-800 ${
        disabled ? 'bg-opacity-40 cursor-not-allowed' : 'hover:bg-yellow-600'
      }`
    case 'secondary':
      return `${widthClass} border-gray-800 bg-gray-500 text-black ${
        disabled ? 'bg-opacity-40 cursor-not-allowed' : 'hover:bg-white'
      }`
    case 'danger':
      return `${widthClass} border-gray-800 bg-red-200 text-white ${
        disabled ? 'bg-opacity-40 cursor-not-allowed' : 'hover:bg-red-500'
      }`
    case 'silent':
      return `${widthClass} border-gray-800 hover:border-white text-gray-600 hover:text-white`
    case `text`:
      return 'w-auto border-0'
  }

  return null
})

const sizeClass = computed(() => {
  switch (size) {
    case 'xs':
      return 'text-xs py-1 px-2'
    case 's':
      return 'text-m p-2'
    case 'm':
      return 'text-title3 p-4'
  }
})
</script>

<template>
  <button
    :type="type"
    class="inline-flex items-center justify-center rounded border transition duration-150 ease-in-out"
    :class="[variantClass, sizeClass]"
    :disabled="disabled || loading"
    v-bind="$attrs"
  >
    <template v-if="loading">
      <IconLoading />
      <template v-if="loadingLabel">
        {{ loadingLabel }}
      </template>
    </template>
    <slot v-else />
  </button>
</template>
