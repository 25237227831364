import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import UserService from '../services/userService'
import { LOCAL_STORAGE_KEYS } from '../constants'
import UserSubscription from '../subscriptions/UserSubscription'

const { GUEST_NAME } = LOCAL_STORAGE_KEYS

export const useUserStore = defineStore('user', () => {
  const user = ref(null)
  const guestName = ref(localStorage.getItem(GUEST_NAME))
  const isLoading = ref(false)
  const subscription = ref(null)
  const notificationCount = ref(0)

  async function loadUserData() {
    isLoading.value = true
    try {
      const data = await UserService.load()

      if (data.isGuest && guestName.value?.length) {
        data.name = guestName.value
      }
      user.value = data
      return true
    } catch (error) {
      console.error('Failed to fetch user', error)
      return false
    } finally {
      isLoading.value = false
    }
  }

  async function signup(form) {
    isLoading.value = true
    try {
      const { data, token } = await UserService.signup(form)

      user.value = data
      localStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN, token)
    } finally {
      isLoading.value = false
    }
  }

  async function signin(form) {
    isLoading.value = true
    try {
      const { data, token } = await UserService.signin(form)

      user.value = data
      localStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN, token)
    } finally {
      isLoading.value = false
    }
  }

  async function signout() {
    await UserService.signout()
    await afterSignOut()
  }

  async function afterSignOut() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)
    guestName.value = null
    await loadUserData()
  }

  async function requestNewPassword(email) {
    isLoading.value = true
    try {
      const result = await UserService.requestNewPassword(email)
      return result
    } finally {
      isLoading.value = false
    }
  }

  async function updatePassword(password, passwordConfirmation, token) {
    isLoading.value = true
    try {
      await UserService.updatePassword(password, passwordConfirmation, token)
    } finally {
      isLoading.value = false
    }
  }

  async function deleteAccount(password) {
    isLoading.value = true
    try {
      const result = await UserService.deleteAccount(password)
      if (!result) {
        return false
      }
      await afterSignOut()
      return true
    } finally {
      isLoading.value = false
    }
  }

  async function update(form) {
    isLoading.value = true
    try {
      const data = await UserService.update(form)
      user.value = data
    } finally {
      isLoading.value = false
    }
  }

  async function observeUser() {
    subscription.value?.disconnect()
    subscription.value = new UserSubscription(user.value)
  }

  function stopObserveUser() {
    subscription.value?.disconnect()
  }

  watch(guestName, newValue => {
    if (newValue?.length) {
      localStorage.setItem(GUEST_NAME, newValue)
      user.value = {
        ...user.value,
        ...{ name: newValue }
      }
    } else {
      localStorage.removeItem(GUEST_NAME)
      user.value = {
        ...user.value,
        ...{ name: 'Gast' }
      }
    }
  })

  watch(user, newVaule => {
    console.log('user set!', newVaule.value)
    if (user.value?.isGuest === false) {
      observeUser()
    } else {
      stopObserveUser()
    }
  })

  window.addEventListener('message', event => {
    if (Object.keys(event.data).includes('notificationCount')) {
      notificationCount.value = event.data.notificationCount
    }
  })

  return {
    user,
    guestName,
    isLoading,
    notificationCount,
    loadUserData,
    signup,
    signin,
    signout,
    update,
    requestNewPassword,
    updatePassword,
    deleteAccount
  }
})
