<template>
  <a11y-dialog id="user-notification-modal" @dialog-ref="assignDialogRef">
    <NavigationBar
      v-if="isVisible"
      :title="$t('modals.lobbyInvite.title')"
      :is-absolute="false"
      :is-floating-bar="true"
    >
      <template #left-button>&nbsp;</template>
    </NavigationBar>

    <section class="p-5" v-if="lobby">
      <p class="mb-4">{{ $t('modals.lobbyInvite.description', { name: lobby.user.name }) }}</p>

      <div class="mb-4 flex items-center gap-4">
        <img
          :src="lobby.user.avatarSmallUrl"
          class="b-color-white h-16 w-16 flex-shrink-0 rounded-full border-2 object-cover"
        />
        <span class="flex-grow">
          {{ lobby.user.name }}
        </span>
      </div>

      <div class="flex gap-4">
        <ActionButton variant="danger" @click="() => dialog.hide()">
          {{ $t('modals.lobbyInvite.buttons.dismiss') }}
        </ActionButton>

        <ActionButton @click="acceptInvite">
          {{ $t('modals.lobbyInvite.buttons.accept') }}
        </ActionButton>
      </div>
    </section>
  </a11y-dialog>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useGameStore } from '../stores/game'
import { storeToRefs } from 'pinia'
import { useUserStore } from '../stores/user'
import NavigationBar from './NavigationBar.vue'
import ActionButton from './ActionButton.vue'
import LobbyService from '../services/lobbyService'

const emit = defineEmits(['close'])

const userStore = useUserStore()
const gameStore = useGameStore()
const { user } = storeToRefs(userStore)
const { state } = storeToRefs(gameStore)
const router = useRouter()
const dialog = ref(null)
const lobby = ref(null)
const isVisible = ref(false)

const isGameActive = computed(() => {
  return ['created', 'playing'].includes(state.value)
})

function assignDialogRef(dialogValue) {
  dialog.value = dialogValue
  if (!dialog.value) {
    return
  }
  dialog.value.$el.dataset.position = 'center'
  dialog.value.on('hide', onModalClose)
}

function onModalClose() {
  isVisible.value = false
  if (!lobby.value) {
    return
  }

  LobbyService.dismiss(lobby.value.id)
  lobby.value = null
}

function onMessage(event) {
  if (
    event.data?.type === 'LobbyInvite' &&
    event.data.values?.lobby &&
    event.data.values.lobby.user.id !== user.value.id &&
    !isGameActive.value
  ) {
    lobby.value = event.data.values.lobby
    isVisible.value = true
    dialog.value.show()
  }
}

function acceptInvite() {
  const id = lobby.value.id
  lobby.value = null
  router.push({ name: 'lobby', params: { id } })
  dialog.value.hide()
}

onMounted(() => {
  window.addEventListener('message', onMessage)
})

onUnmounted(() => {
  window.removeEventListener('message', onMessage)
})
</script>
