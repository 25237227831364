import http from './http'
import APIService from './apiService'
import { API_ENDPOINTS } from '../constants'

export default {
  async load(id) {
    const url = APIService.buildUrl(API_ENDPOINTS.lobby, { id })
    const { data } = await http.get(url)
    return data
  },

  async open() {
    const url = APIService.buildUrl(API_ENDPOINTS.lobbies)
    const { data } = await http.get(url)
    return data
  },

  async create(userIds) {
    const url = APIService.buildUrl(API_ENDPOINTS.lobbies)
    const { data } = await http.post(url, { userIds })
    return data
  },

  async dismiss(id) {
    const url = APIService.buildUrl(API_ENDPOINTS.lobby, { id })
    const { data } = await http.delete(url)
    return data
  }
}
