<template>
  <router-view class="main-view" v-slot="{ Component, route }">
    <transition :name="route.meta.transition || 'fade'">
      <component :is="Component" :key="route.fullPath" />
    </transition>
  </router-view>

  <notification v-bind="notification" @close="notification.show = false">
    <template #title> {{ notification.title }}</template>
    <template #description>
      {{ notification.description }}
    </template>
  </notification>

  <UserNotificationModal />
</template>

<script setup>
import { onMounted } from 'vue'
import { soundManager } from './services/SoundManager'
import useNotification from './composables/notification'
import Notification from './components/Notification.vue'
import UserNotificationModal from './components/UserNotificationModal.vue'

const { notification } = useNotification()

onMounted(() => {
  soundManager.playMusic()
})
</script>

<style scoped>
.main-view {
  padding-top: var(--inset-top);
  padding-bottom: var(--inset-bottom);
}
</style>
